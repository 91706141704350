import { createAction, props } from '@ngrx/store';

import { ArchiveType, InvoiceArchive } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Invoice Archives] Load Request',
  props<{
    properties: number[];
    invoiceType: 'invoice' | 'received' | string;
  }>(),
);

export const loadSuccess = createAction(
  '[Invoice Archives] Load Success',
  props<{ invoiceArchives: InvoiceArchive[] }>(),
);

export const loadFailure = createAction(
  '[Invoice Archives] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Invoice Archives] Create Request',
  props<{ request: SearchParams; archiveExportFormat: ArchiveType }>(),
);

export const createSuccess = createAction(
  '[Invoice Archives] Create Success',
  props<{
    properties: number[];
    invoiceType: 'invoice' | 'received' | string;
  }>(),
);

export const createFailure = createAction(
  '[Invoice Archives] Create Failure',
  props<{ error: any }>(),
);

export const createInvoicesReceiptsArchiveRequest = createAction(
  '[Invoice Archives] Create Invoices Receipts Request',
  props<{ request: SearchParams; archiveExportFormat: ArchiveType }>(),
);

export const createInvoicesReceiptsArchiveSuccess = createAction(
  '[Invoice Archives] Create Invoices Receipts Success',
  props<{
    properties: number[];
    invoiceType: 'invoice' | 'received' | string;
  }>(),
);

export const createInvoicesReceiptsArchiveFailure = createAction(
  '[Invoice Archives] Create Invoices Receipts Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Invoice Archives] Delete Request',
  props<{
    id: number;
  }>(),
);

export const deleteSuccess = createAction(
  '[Invoice Archives] Delete Success',
  props<{
    id: number;
  }>(),
);

export const deleteFailure = createAction(
  '[Invoice Archives] Delete Failure',
  props<{ error: any }>(),
);

export const cancelCreationRequest = createAction(
  '[Invoice Archives] Cancel Creation Request',
  props<{ id: number }>(),
);

export const cancelCreationSuccess = createAction(
  '[Invoice Archives] Cancel Creation Success',
  props<{ id: number }>(),
);

export const cancelCreationFailure = createAction(
  '[Invoice Archives] Cancel Creation Failure',
  props<{ error: any }>(),
);

export const updateProgressPercentagesRequest = createAction(
  '[Invoice Archives] Update Progress Percentages  Request',
  props<{ ids: number[] }>(),
);

export const updateProgressPercentagesSuccess = createAction(
  '[Invoice Archives] Update Progress Percentages  Success',
  props<{ invoiceArchives: InvoiceArchive[] }>(),
);

export const updateProgressPercentagesFailure = createAction(
  '[Invoice Archives] Update Progress Percentages  Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[InvoiceArchives] Reset State');
