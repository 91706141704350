import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { InvoiceArchivesService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class InvoiceArchivesStoreEffects {
  constructor(
    private dataService: InvoiceArchivesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties, invoiceType }) =>
        this.dataService.load(properties, invoiceType).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({ invoiceArchives: response.data }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, archiveExportFormat }) =>
        this.dataService.create(request, archiveExportFormat).pipe(
          mergeMap(() => {
            this.notifications.createSuccess('invoice_archive');
            return [
              fromActions.createSuccess({
                properties: request.property_id as number[],
                invoiceType: 'invoice',
              }),
              fromActions.loadRequest({
                properties: request.property_id as number[],
                invoiceType: 'invoice',
              }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  createInvoicesReceiptsArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createInvoicesReceiptsArchiveRequest),
      switchMap(({ request, archiveExportFormat }) =>
        this.dataService
          .createInvoicesReceiptsArchive(request, archiveExportFormat)
          .pipe(
            mergeMap(() => {
              this.notifications.createSuccess('invoice_archive');
              return [
                fromActions.createInvoicesReceiptsArchiveSuccess({
                  properties: request.property_id as number[],
                  invoiceType: 'received',
                }),
                fromActions.loadRequest({
                  properties: request.property_id as number[],
                  invoiceType: 'received',
                }),
              ];
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                fromActions.createInvoicesReceiptsArchiveFailure(error),
              );
            }),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ id }) =>
        this.dataService.delete(id).pipe(
          map(() => {
            this.notifications.deleteSuccess('invoice_archive');
            return fromActions.deleteSuccess({ id });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  cancelCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelCreationRequest),
      switchMap(({ id }) =>
        this.dataService.cancelCreation(id).pipe(
          map(() => fromActions.cancelCreationSuccess({ id })),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.cancelCreationFailure(error));
          }),
        ),
      ),
    ),
  );

  updateProgressPercentages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProgressPercentagesRequest),
      switchMap(({ ids }) =>
        this.dataService.updateProgressPercentages(ids).pipe(
          map((response: IResponseSuccess) =>
            fromActions.updateProgressPercentagesSuccess({
              invoiceArchives: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateProgressPercentagesFailure(error));
          }),
        ),
      ),
    ),
  );
}
